/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Fullmap, FullmapWrap, Column, ColumnWrap, ColumnWrapper, Title, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"homephone"} description={"Služby"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Fullmap name={"q13ep9ji9wi"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Fullmap name={"lgz2ao2y4fp"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Column name={"prázdná"} layout={"l30"}>
        </Column>


        <Column className="pb--60 pt--60" name={"ih076rdtikh"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"Zvonkové systémy"}>
              </Title>

              <Text className="text-box" content={"Bez domácích komunikačních systémů častěji známe jaké zvonky, elektronický vrátný a podobně si dnes již jen těžko představíme panelové domy, firemní nebo statní objekty. Na trhu dnes najdeme systémy od jednoduchých které umožňují audio komunikaci až po systémy IP s připojením do internetu a přenosem obrazu na mobilní zařízení.<br><br>V tomto oboru máme bohaté zkušenosti a to již od středoškolských let. Díky tomu Vám jsme schopni nabídnou systém který splní přesně Vaše kritéria. Nabízíme systémy české výroby nebo světoznámých zahraničních značek se zastoupením na českém trhu. Provádíme servis stávajících systému různých značek a výrobců.<br><br>V případě požadavku na systém nový Vás navštívíme na objektu kde se bude konat realizace. Náš technik vše zaměří, vyslechne si Vaše přání a požadavky a na základě těchto informací navrhne řešení a případné doplňující funkce systému pro zvážení zdali budou užitečné.<br><br>V případě že současné slaboproudé rozvody jsou dostačující pro nový systém jsou využity s jistými úpravami. Což zkracuje dobu realizace ale zároveň snižuje náklady investora. V případě že klient požaduje systém který má vyšší požadavky na kabeláž jsme schopni vybudovat nové rozvody tak aby odpovídali normám a potřebám nového systému s přihlédnutím na případné rozšiřování systému o prvky i funkcionalitu.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1pn2jyn pb--80 pt--80" name={"kontakt"} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=350x_.png);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3" anim={"7"} animS={"3"}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: white;\">Rádi vyhovíme Vašim přáním a požadavkům</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}